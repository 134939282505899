.queryform-section {
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
}

.queryform-section .title {
  color: #fff;
  font-weight: 700;
  line-height: 1.2em;
  font-size: 3rem;
  letter-spacing: 0.5px;
}
.queryform-section .subtitle {
  color: #66fcf1;
  font-weight: 400;
  line-height: 2em;
  font-size: 1.1rem;
  letter-spacing: 0.3px;
}
.queryform-section .form {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
}
.queryform-section .form .element-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}
.queryform-section .form .inputfield,
.queryform-section .form .textfield {
  background: #242424;
  padding: 1rem 1.5rem;
  width: 45%;
  color: #fff;
  line-height: 1.8em;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.queryform-section .form .textfield {
  width: 100%;
  margin: 2rem 0;
}
.queryform-section .form .submit-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.queryform-section .form .submit-btn .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 10px 15px 10px 15px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
  text-transform: uppercase;
}
.queryform-section .form .submit-btn .btn:hover {
  border: 2px solid #39928b;
}

@media screen and (max-width: 768px) {
  .queryform-section {
    justify-content: center;
  }
  .queryform-section .title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  .queryform-section .subtitle {
    margin-bottom: 1rem;
  }
  .queryform-section .form {
    padding: 1rem 0rem;
  }
  .queryform-section .form .element-row {
    flex-direction: column;
    margin: 0rem;
  }
  .queryform-section .form .inputfield,
  .queryform-section .form .textfield {
    width: 100%;
    margin-bottom: 2rem;
  }
}
