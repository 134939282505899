.particles-container {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 10;
  /* background: lightcoral;
  opacity: 0.2; */
}

.particles-container .particles-canvas {
  position: absolute !important;
  top: 0%;
  left: 0%;
  width: 100%;
  background: transparent;
}
