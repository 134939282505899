.work-container {
  background: #0b0c10;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

.work-container .particles-container {
  height: 50vh !important;
}

.work-container .header {
  z-index: 100;
  background: transparent;
}
.header-container {
  position: relative;
  width: 100%;
  height: 6rem !important;
}
.header-details {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.work-container .work-details {
  position: relative;
  width: 100%;
  min-height: 30vh;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  margin-top: 0rem !important;
  background: transparent;
  margin-bottom: 4rem;
}
.work-container .work-details .work-info {
  position: relative;
  width: 100%;
  height: 100%;
}
.work-container .work-details .work-info .intro {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.work-container .work-details .work-info .intro .title {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.work-container .work-details .work-info .info {
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.work-container .work-details .work-info .info .text {
  font-size: 1.2rem;
  font-weight: 400;
  color: #757589;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin: 1.5rem 0;
}

.work-container .single-work {
  width: 100%;
  height: 100%;
}

.work-container .single-work .work-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.work-container .single-work .work-img .app-links {
  position: absolute;
  width: 30%;
  left: 69%;
  top: 97.25%;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.work-container .single-work .work-img .app-links .link {
  transition: all 0.3s linear;
  width: 10rem;
}

.work-container .single-work .work-img .app-links .link a img {
  width: 100%;
  cursor: pointer;
}

.work-container .single-work .work-img .app-links .link:hover {
  /* transform: scale(1.1); */
}
.work-container .single-work .work-img .app-links .apple {
  /* margin-top: 0.5rem; */
  width: 9rem;
  margin-left: 2rem;
}
.work-container .single-work .work-img img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .work-container .particles-container {
    height: 45vh !important;
  }

  .work-container .work-details .work-info .intro .title {
    font-size: 2.5rem;
    text-align: center;
  }
  .work-container .work-details .work-info .info {
    width: 95%;
    text-align: justify;
  }
  .work-container .work-details .work-info .info .text {
    font-size: 1rem;
    text-align: justify;
    line-height: 1.6em;
  }
  .work-container .single-work .work-img .app-links {
    width: 60%;
    left: 50%;
    top: 97%;
    transform: translate(-50%, 0%);
    justify-content: center;
  }
  .work-container .single-work .work-img .app-links .link {
    width: 6rem;
  }
  .work-container .single-work .work-img .app-links .apple {
    width: 5.5rem;
  }
  .work-container .single-work .work-img .app-links.single-img {
    top: 97%;
  }
}

@media screen and (min-width: 770px) and (max-width: 1024px) {
  .work-container .particles-container {
    height: 40vh !important;
  }
  .work-container .single-work .work-img .app-links.single-img {
    top: 95.5%;
  }
}

@media screen and (max-width: 545px) {
  .work-container .single-work .work-img .app-links {
    top: 96.5%;
  }
  .work-container .single-work .work-img .app-links.single-img {
    top: 96%;
  }
}

@media screen and (max-width: 465px) {
  .work-container .single-work .work-img .app-links {
    top: 96%;
  }
}

@media screen and (max-width: 368px) {
  .work-container .single-work .work-img .app-links.single-img {
    top: 95.25%;
  }
}
