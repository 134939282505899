@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* common design */

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #0b0c10;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}
.horizontal-bar {
  border-bottom: 3px solid #66fcf1 !important;
}
.section {
  padding: 0rem 5rem;
  margin-top: 1rem;
}
.highlight-border {
  border: 3px solid #66fcf1 !important;
}
.highlight-card {
  background: #66fcf1 !important;
}
.highlight-card .title,
.highlight-card .desc,
.highlight-card .icon {
  color: #0b0c10 !important;
}
.vertical-bar {
  width: 50%;
  height: 3rem;
  border-right: 3px solid #66fcf1;
}

/* end of common design */

/*  start header */

.header {
  position: sticky;
  margin: auto;
  overflow-x: hidden;
  padding: 0rem 4rem;
  padding-top: 1rem;
  background: #0b0c10;
  /* background: lightcoral; */
  z-index: 0;
}

.header-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  text-transform: uppercase;
  z-index: 10000;
  background: #0b0c10;
}
.header-modal.show-modal {
  display: flex;
  backdrop-filter: blur(10px);
  transition: 0.1s all ease-out;
  animation: opennavmenu 0.1s ease-out;
}
.header-modal.hide-modal {
  display: none;
}
@keyframes opennavmenu {
  0% {
    height: 0%;
    opacity: 0;
  }
  25% {
    height: 25%;
    opacity: 0.25;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  75% {
    height: 75%;
    opacity: 0.75;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
.close-icon {
  position: absolute;
  top: 3%;
  right: 7%;
  color: #fff;
}
.header .logo {
  /* width: 8rem;
  height: 4rem; */
}
.header .logo p {
  color: #fff;
  letter-spacing: 0.3px;
  font-size: 1.5rem;
  font-weight: 600;
}

.header .logo img {
  width: 100%;
  height: 100%;
}

.header-modal ul li,
.header ul li {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2em;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
}
.header-modal ul li {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

/* end header */

/* Home Page */

/* inquiry-section  */
.inquiry-section {
  width: 100vw;
  height: 65vh;
  position: relative;
  background-image: url("./images/bg-img.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.inquiry-section .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 4rem;
}
.inquiry-section .details .title {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  margin-bottom: 5rem;
}
.inquiry-section .details .inquiry-btn .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 20px 30px 20px 30px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
}
.inquiry-section .details .inquiry-btn .btn:hover {
  border: 2px solid #39928b;
}
.inquiry-section .vertical-bar {
  margin-top: 0rem;
  margin-bottom: 0rem;
  height: 5rem !important;
  position: relative;
  top: -8%;
}

/* footer */
.footer-section {
  background: #0b0c10;
  border: 1px solid #262626;
  margin-top: 0rem;
}
.footer-section .footer-info {
  padding: 1rem 0;
}
.footer-section .footer-info .footer-title,
.footer-section .footer-info .footer-text {
  color: #fff;
  font-size: 0.8rem;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 1.8em;
  margin: 1rem 0;
}
.footer-section .footer-info .footer-text {
  color: #c6c6c6;
}

/* reponsive design */
@media screen and (max-width: 768px) {
  .home {
    max-width: 100%;
    /* margin: 0; */
    overflow-x: hidden;
  }
  .section {
    padding: 0rem 1rem;
  }
  .header {
    padding: 0 1rem;
  }
  .header .logo {
    width: 6.5rem;
    height: 3.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  }

  .header-modal ul li {
    padding-left: 2rem;
  }
  .inquiry-section {
    width: 100vw;
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vh;
    background-attachment: scroll;
    margin-bottom: 0rem;
  }
  .inquiry-section .details {
    padding: 0 1rem;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 7rem;
  }
  .inquiry-section .details .title {
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  .inquiry-section .vertical-bar {
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 5rem !important;
    position: relative;
    top: -10%;
  }

  .footer-section {
    padding: 0 1rem;
  }
  .footer-section .footer-info {
    margin-left: 0.5rem;
  }
}
