.services-container {
  background: #0b0c10;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}
.services-container .particles-container {
  height: 50vh !important;
}
.services-container .header {
  z-index: 100;
  background: transparent;
}
.header-container {
  position: relative;
  width: 100%;
  height: 6rem !important;
}
.header-details {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.services-container .service-details {
  position: relative;
  width: 100%;
  height: 100%;
}
.services-container .service-info {
  position: relative;
  width: 100%;
  height: 45vh;
}
.services-container .intro {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.services-container .intro .title {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.services-container .info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}
.services-container .info .title {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.services-container .info .text {
  font-size: 1rem;
  font-weight: 400;
  color: #66fcf1;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin: 1.5rem 0;
}
.services-container .services-section .cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}
.services-container .services-section .services-section-card {
  background: #121212;
  padding: 2.5rem;
  width: 40%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 0.1rem;
  word-wrap: break-word;
  margin-top: 1rem;
  margin-right: 1rem;
  border: 3px solid transparent;
}
.services-container .services-section .services-section-card {
  border: none;
  border-bottom: 3px solid #fff;
  height: 20rem;
}
.services-container .services-section .services-section-card:hover {
  border-bottom: 3px solid #66fcf1;
}

.services-container .services-section .services-section-card .title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2em;
  color: #fff;
  margin: 1rem 0;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  margin-top: 2rem;
}
.services-container .services-section .services-section-card .desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  color: #fff;
  letter-spacing: 0.3px;
  margin-bottom: 1rem;
}
/* .services-container
  .services-section
  .services-section-card:nth-child(2)
  .title {
  margin-top: 1rem;
} */
.services-container .services-section .services-section-card .desc .first-para {
  display: none;
}
.services-container
  .services-section
  .services-section-card
  .desc
  .second-para {
  display: block;
}

.services-container .services-section .services-section-card .icons {
  width: 100%;
  display: flex;
  align-items: center;
}
.services-container .services-section .services-section-card .icons .img-icon {
  margin: 1rem 0;
  width: 2.5rem;
  height: 3rem;
  margin-right: 1rem;
}
.services-container .services-section .services-section-card .icons .android {
  width: 3rem;
  height: 3.2rem;
}
.services-container .services-section .services-section-card .icons .ios {
  width: 2.4rem;
  height: 2.65rem;
}

.services-container
  .services-section
  .services-section-card
  .icons
  .img-icon
  img {
  width: 100%;
  height: 100%;
}
.services-container .services-section .services-section-card .icons .icon {
  font-size: 1.2rem;
  margin-right: 1rem;
  color: #66fcf1;
}
.services-container .services-section .services-section-card .desc {
  margin-bottom: 0.5rem;
}
.services-container .services-section .services-section-card .read-more-btn {
  margin-top: 1.5rem;
  display: none;
}
.services-container
  .services-section
  .services-section-card
  .read-more-btn
  .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 10px 15px 10px 15px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
}
.services-container
  .services-section
  .services-section-card
  .read-more-btn
  .btn:hover {
  border: 2px solid #39928b;
}

.services-container .services-section .vertical-bar {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

@keyframes servicecardanimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.1;
  }
}

.services-container .projects-links-section {
  width: 100vw;
  height: 50vh;
  position: relative;
  background-image: url("./images/bg-img-2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.services-container .projects-links-section .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.services-container .projects-links-section .details .title {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  margin-bottom: 5rem;
}
.services-container .projects-links-section .projects-btn .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 20px 30px 20px 30px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
  text-transform: uppercase;
}
.services-container .projects-links-section .projects-btn .btn:hover {
  border: 2px solid #39928b;
}

.services-container .skills-section {
  background: #121212;
  padding: 4rem 0;
}
.services-container .skills-section .details {
  padding: 4rem 2rem;
  display: flex;
  width: 85%;
  margin: auto;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
.services-container .skills-section .details .info {
  background: #121212;
  width: 50%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.services-container .skills-section .details .info .title {
  color: #fff;
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.services-container .skills-section .details .info .text {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  text-align: justify;
  width: 80%;
  /* margin-bottom: 1rem; */
}

.services-container .skills-section .details .list {
  padding: 0 2rem;
  width: 50%;
}
.services-container .skills-section .details .list ul {
  width: 100%;
  /* margin-top: 2rem; */
}
.services-container .skills-section .details .list ul li {
  width: 100%;
  border-bottom: 2px solid #66fcf1;
  margin-bottom: 2rem;
  color: #fff;
  line-height: 2em;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  padding-bottom: 0.25rem;
}

@media screen and (max-width: 768px) {
  .services-container {
    max-width: 100%;
    overflow-x: hidden;
  }

  .services-container .particles-container {
    height: 55vh !important;
  }
  .services-container .intro {
    height: 70%;
  }
  .services-container .intro .title {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2em;
    letter-spacing: 0.5px;
  }
  .services-container .info {
    margin-top: 0rem;
  }
  .services-container .info .title {
    font-size: 2.5rem;
  }

  .services-container .services-section .services-section-card {
    width: 97%;
    height: auto;
    justify-content: center;
    margin: 0rem auto;
    margin-top: 1rem;
  }
  .services-container .services-section .services-section-card .title {
    margin-top: 1rem;
  }
  .services-container .projects-links-section .details .title {
    font-size: 2rem;
    margin: 3.5rem auto;
    text-align: center;
  }

  .services-container .skills-section {
    padding: 2rem 0;
  }
  .services-container .skills-section .details {
    padding: 2rem 0;
    flex-direction: column;
    width: 100%;
  }
  .services-container .skills-section .details .info {
    width: 100%;
    padding: 0 2rem;
    align-items: center;
    justify-content: center;
  }
  .services-container .skills-section .details .info .title {
    font-size: 2rem;
    margin-bottom: 0rem;
  }
  .services-container .skills-section .details .info .text {
    width: 100%;
  }
  .services-container .skills-section .details .list {
    width: 100%;
  }
}

@media screen and (max-width: 940px) and (min-width: 770px) {
  .services-container .services-section .services-section-card {
    height: 30rem;
  }
}
