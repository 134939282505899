.works-container {
  background: #0b0c10;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}
.works-container .particles-container {
  height: 50vh !important;
}
.works-container .header {
  z-index: 100;
  background: transparent;
}
.header-container {
  position: relative;
  width: 100%;
  height: 6rem !important;
}
.header-details {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.works-container .works-details {
  position: relative;
  width: 100%;
  height: 100%;
}
.works-container .works-info {
  position: relative;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 45vh;
}
.works-container .intro {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.works-container .intro .title {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.works-container .info {
  /* background: #0b0c10; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}
.works-container .info .title {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.works-container .info .text {
  font-size: 1rem;
  font-weight: 400;
  color: #66fcf1;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin: 1.5rem 0;
}

.works-container .works-section {
  margin: 2rem 0;
  background: #0b0c10;
}

.works-container .works-section .works-imgs {
  width: 100%;
  height: 100%;
  position: relative;
}
.works-container .works-section .works-imgs .row-one {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
}
.works-container .works-section .works-imgs .row-one img {
  width: 85%;
  height: 100%;
  cursor: pointer;
}
.works-container .works-section .works-imgs .row-two {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
}
.works-container .works-section .works-imgs .row-two .img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.works-container .works-section .works-imgs .row-two .img:nth-child(2) {
  justify-content: flex-start;
  /* padding-left: 1rem;
  padding-right: 0rem; */
}
.works-container .works-section .works-imgs .row-two .img img {
  width: 85%;
  height: 100%;
  padding: 0rem 1rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .works-container {
    max-width: 100%;
    overflow-x: hidden;
  }
  .works-container .particles-container {
    height: 55vh !important;
  }
  .works-container .intro {
    height: 70%;
  }
  .works-container .intro .title {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2em;
    letter-spacing: 0.5px;
  }
  .works-container .info {
    margin-top: 0rem;
  }
  .works-container .info .title {
    margin-top: 0rem;
    font-size: 2.5rem;
  }
  .works-container .works-section .works-imgs .row-one img {
    width: 100%;
    height: 100%;
  }
  .works-container .works-section .works-imgs .row-two {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .works-container .works-section .works-imgs .row-two .img {
    width: 100%;
    justify-content: center;
  }
  .works-container .works-section .works-imgs .row-two .img:nth-child(2) {
    justify-content: center;
    margin: 2rem 0;
  }
  .works-container .works-section .works-imgs .row-two .img img {
    width: 100%;
    padding: 0rem;
  }
}
