@tailwind base;
@tailwind components;
@tailwind utilities;

.home {
  background: #000;
  width: 100%;
  position: relative;
}
.home .particles-container {
  height: 80vh !important;
}
.home .header {
  background: transparent !important;
  z-index: 100;
  /* background: lightcoral !important; */
}
.header-container {
  position: relative;
  width: 100%;
  height: 6rem !important;
}
.header-details {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}
/*  hero-section */

.hero-section {
  position: relative;
  background: #000000;
  width: 100%;
  height: 60vh;
  padding: 0rem;
  /* background: lightcoral; */
}
.hero-section .details {
  height: auto;
  position: relative;
  /* background: lightcoral; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0rem;
}

.hero-section .details .home-details {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  margin-top: 1rem;
  margin-top: 1rem;
}
.hero-section .details .home-details .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.hero-section .details .home-details .title {
  font-size: 2.5rem;
  color: #fff;
  margin-top: 1rem;
}
.hero-section .details .home-details .subtitle {
  font-size: 3rem;
  color: #fff;
  margin: 2rem 0;
}
.hero-section .details .home-details .text {
  font-size: 1.2rem;
  color: #fff;
  line-height: 1.4em;
  width: 80%;
  margin: auto;
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 3rem;
}
.hero-section .details .logo-text .row-one,
.hero-section .details .logo-text .row-two {
  position: relative;
  bottom: 4%;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #fff;
}
.hero-section .logo {
  width: 10rem;
  /* height: 2rem; */
}

.hero-section .logo img {
  position: relative;
  left: 255%;
  width: 100%;
  /* background: lightgreen; */
}

.hero-section .title {
  font-size: 5rem;
  line-height: 1.2em;
  font-size: 800;
}

/* hero-section,service-section cards */
.hero-section .cards,
.services-section .cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.services-section .cards {
  justify-content: space-evenly;
}
.hero-section-card,
.services-section-card {
  background: #121212;
  padding: 2.5rem;
  width: 30%;
  height: auto;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-evenly; */
  flex-direction: column;
  border-radius: 0.1rem;
  word-wrap: break-word;
  margin-top: 1rem;
  margin-right: 1rem;
  border: 3px solid transparent;
}
.services-section {
  margin-top: 4rem;
}
.services-section-card {
  width: 40%;
}
.hero-section-card:hover {
  border: 3px solid #66fcf1;
}
.services-section-card {
  border: none;
  border-bottom: 3px solid #fff;
  height: 25rem;
}
.services-section-card:hover {
  border-bottom: 3px solid #66fcf1;
}

.hero-section-card .title,
.services-section-card .title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2em;
  color: #fff;
  margin: 1rem 0;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.services-section-card .icons {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}
.hero-section-card .icon,
.services-section-card .icons .img-icon {
  margin: 1rem 0;
  width: 2.5rem;
  height: 3rem;
  margin-right: 1rem;
}
.services-section-card .icons .android {
  width: 3rem;
  height: 3.2rem;
}
.services-section-card .icons .ios {
  width: 2.4rem;
  height: 2.65rem;
}
.services-section-card .icons .img-icon img {
  width: 100%;
  height: 100%;
}
.services-section-card .icons .icon {
  font-size: 1.2rem;
  margin-right: 1rem;
  color: #66fcf1;
}
.hero-section-card .desc,
.services-section-card .desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  color: #fff;
  letter-spacing: 0.3px;
  margin-bottom: 1rem;
  height: 50%;
}
.services-section-card .desc .first-para {
  display: block;
}
.services-section-card .desc .second-para {
  display: none;
}

.hero-section-card .divider {
  width: 4rem;
  border-bottom: 3px solid #66fcf1;
  margin: 1rem 0;
}

.services-section-card .icon {
  margin: 1rem 0;
}
.services-section-card .desc {
  margin-bottom: 0.5rem;
}
.services-section-card .read-more-btn {
  margin-top: 1.5rem;
  display: none;
}
.services-section-card .read-more-btn .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 10px 15px 10px 15px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
}
.services-section-card .read-more-btn .btn:hover {
  border: 2px solid #39928b;
}

.services-section .vertical-bar {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

@keyframes servicecardanimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.1;
  }
}

/* service-section */
.services-section {
  width: 100%;
  background: #0b0c10;
  height: auto;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}
.services-section .details {
  padding: 5rem 0rem;
}
.services-section .details .title {
  display: flex;
  align-items: center;
  height: 100%;
}
.services-section .details .title .horizontal-bar {
  width: 4rem;
  height: 8px;
  color: #66fcf1;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.services-section .details .title h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2em;
  color: #fff;
  letter-spacing: 0.5px;
  padding-left: 2rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.services-section .details .subtitle {
  line-height: 2em;
  font-size: 1.1rem;
  font-weight: 400;
  color: #66fcf1;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

/* works section */
.works-section {
  background: #121212;
  width: 100%;
  height: auto;
  padding-bottom: 1rem;
  margin-top: 0rem;
}

.works-section .details {
  padding: 5rem 0rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.works-section .details .title {
  display: flex;
  align-items: center;
}

.works-section .details .title .horizontal-bar {
  width: 4rem;
  height: 8px;
  color: #66fcf1;
  margin-bottom: 0.5rem;
}
.works-section .details .title h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2em;
  color: #fff;
  letter-spacing: 0.5px;
  padding-left: 2rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.works-section .details .subtitle {
  line-height: 2em;
  font-size: 1.1rem;
  font-weight: 400;
  color: #66fcf1;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
/* .works-section .details .work-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.works-section .details .work-btn .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 20px 30px 20px 30px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
}
.works-section .details .work-btn .btn:hover {
  border: 2px solid #39928b;
}
.works-section .vertical-bar {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

/* slider image container */
.img-container {
  width: 100%;
  height: 70vh;
  display: flex;
  flex: 1;
}

.img-container .img-box {
  width: 20%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease;
  cursor: pointer;
}
.img-cover::before {
  background: linear-gradient(to top, #000, transparent);
  content: "";
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 50%;
  z-index: -1;
  transition: all 0.3s linear;
}
.img-cover:hover::before {
  z-index: 10;
  animation: animateimgcover 1s linear;
  transition: all 0.3s linear;
}
.img-title::after {
  content: "Badminton";
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 50%;
  color: #66fcf1;
  cursor: pointer;
  text-align: center;
  z-index: -1;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.3px;
  animation: animateimgcoverdown 1s linear;
  transition: all 0.3s linear;
  text-transform: capitalize;
}
.img-title:hover::after {
  z-index: 10;
  animation: animateimgcover 1s linear;
  transition: all 0.3s linear;
}

@keyframes animateimgcover {
  0% {
    height: 0%;
    opacity: 0;
  }
  25% {
    height: 25%;
    opacity: 0.25;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  100% {
    height: 50%;
    opacity: 1;
  }
}

@keyframes animateimgcoverdown {
  0% {
    z-index: 1;
    height: 50%;
    opacity: 1;
  }
  50% {
    z-index: 0.5;
    height: 25%;
    opacity: 0.25;
  }
  100% {
    z-index: -1;
    height: 0%;
    opacity: 0;
  }
}

.works-section .works-imgs {
  width: 100%;
  height: 100%;
  position: relative;
}
.works-section .works-imgs .row-one {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
}
.works-section .works-imgs .row-one img {
  width: 85%;
  height: 100%;
  cursor: pointer;
}
.works-section .works-imgs .row-two {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.works-section .works-imgs .row-two .img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.works-section .works-imgs .row-two .img:nth-child(2) {
  justify-content: flex-start;
  /* padding-left: 1rem;
  padding-right: 0rem; */
}
.works-section .works-imgs .row-two .img img {
  width: 85%;
  height: 100%;
  padding: 0rem 1rem;
  cursor: pointer;
  /* display: ; */
}

/* about section */
.about-section {
  margin-top: 0rem;
  position: relative;
  width: 100%;
  background: #0b0c10;
  height: 100%;
  padding-bottom: 2rem;
}
.about-section .details {
  background: #0b0c10;
  padding: 5rem 0rem;
}
.about-section .details .title {
  display: flex;
  align-items: center;
  height: 100%;
}
.about-section .details .title .horizontal-bar {
  width: 4rem;
  height: 8px;
  color: #66fcf1;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
.about-section .details .title h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2em;
  color: #fff;
  letter-spacing: 0.5px;
  padding-left: 2rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.about-section .details .subtitle {
  line-height: 2em;
  font-size: 1.1rem;
  font-weight: 400;
  color: #66fcf1;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.about-section .details .info {
  padding: 0 2rem;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  flex-direction: row;
  margin: 2rem 0;
}
.about-section .details .info .box {
  position: relative;
  min-height: 15rem;
  padding: 0.5rem 0rem;

  text-align: left;
  display: block;
  width: 50%;
  border: 4px solid transparent;
}
.about-section .details .info .box:nth-child(1) {
  border-right: 4px solid #66fcf1;
}
.about-section .details .info .box:nth-child(2) {
  padding-left: 4rem;
}
.about-section .details .info .box .title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 1rem;
}
.about-section .details .info .box .text {
  position: relative;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.8em;
  padding-bottom: 0.5rem;
}
.about-section .details .info .box:nth-child(3) {
  /* border: 4px solid transparent; */
}

/* responsive design */
@media screen and (max-width: 768px) {
  .home {
    max-width: 100%;
    /* margin: 0; */
    overflow-x: hidden;
  }
  .home .particles-container {
    height: 75vh !important;
  }

  .hero-section {
    height: 70vh;
    padding-left: 0rem;
  }
  .hero-section .details {
    display: flex;
    max-width: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .hero-section .details .home-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto;
    position: relative;
    margin-top: 0rem;
    padding: 0rem 1rem;
    height: 100%;
  }
  .hero-section .details .home-details .info {
    margin-top: 1rem;
  }
  .hero-section .details .home-details .title {
    font-size: 2rem;
    color: #fff;
    margin-top: 1rem;
  }
  .hero-section .details .home-details .subtitle {
    font-size: 2.5rem;
    color: #fff;
    margin: 2rem 0;
    line-height: 1.1em;
  }
  .hero-section .details .home-details .text {
    font-size: 1rem;
    color: #fff;
    line-height: 1.3em;
    width: 100%;
    margin: auto;
    text-align: justify;
  }

  .hero-section .details .logo-text .row-one,
  .hero-section .details .logo-text .row-two {
    bottom: 0%;
    font-size: 1.5rem;
  }
  .services-section {
    margin-top: 0rem;
    padding-top: 2rem;
  }
  .hero-section-card,
  .services-section-card {
    width: 97%;
    justify-content: center;
    margin: 0rem auto;
    margin-top: 1rem;
    height: auto;
  }
  .services-section .details {
    padding-left: 1rem;
    padding-right: 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .services-section .details .details-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .services-section .details .title .horizontal-bar,
  .works-section .details .title .horizontal-bar,
  .about-section .details .title .horizontal-bar {
    width: 3rem;
    margin-bottom: 1.25rem;
  }
  .services-section .details .title h1,
  .works-section .details .title h1,
  .about-section .details .title h1 {
    font-size: 2.5rem;
  }
  .works-section .details {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 0rem;
  }
  .works-section .details .work-btn {
    /* background: lightcoral; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
  }

  .works-section .works-imgs .row-one img {
    width: 100%;
    height: 100%;
  }
  .works-section .works-imgs .row-two {
    flex-direction: column;
  }
  .works-section .works-imgs .row-two .img {
    width: 100%;
    justify-content: center;
  }
  .works-section .works-imgs .row-two .img:nth-child(2) {
    justify-content: center;
    margin: 2rem 0;
  }
  .works-section .works-imgs .row-two .img img {
    width: 100%;
    padding: 0rem;
  }
  .about-section .details {
    padding-left: 1rem;
    padding-right: 0rem;
  }
  .about-section .details .details-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .about-section .details .info {
    padding: 0 2rem;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0;
  }
  .about-section .details .info .box {
    border-right: 4px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
  }
  .about-section .details .info .box .text {
    text-align: justify;
  }
  .about-section .details .info .box:nth-child(1) {
    border-right: 4px solid transparent;
  }
  .about-section .details .info .box:nth-child(2) {
    border: 4px solid transparent;
  }
  .about-section .details .info .box:nth-child(2) {
    padding-left: 0rem;
  }
}

@media screen and (max-width: 940px) and (min-width: 770px) {
  .services-section-card {
    height: 40rem;
  }
  .about-section .details .info .box:nth-child(2) {
    padding-left: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .services-section.section,
  .about-section.section,
  .works-section.section {
    padding: 0rem 1rem;
  }
  .about-section .details .info {
    flex-direction: column;
    margin-left: 1rem;
  }
  .about-section .details .info .box {
    width: 100%;
  }
  .about-section .details .info .box:nth-child(2) {
    border: 4px solid transparent;
  }
  .about-section .details .info .box:nth-child(1) {
    border-right: 4px solid transparent;
  }
  .about-section .details .info .box:nth-child(2) {
    padding-left: 0rem;
  }
}
