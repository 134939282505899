.works-img-row {
  display: flex;
  height: 40rem;
  width: 75%;
  overflow-x: scroll;
  overflow-y: hidden !important;
  margin: auto;
  position: relative;
  padding: 0.5rem 0rem;
  scroll-behavior: smooth;
  direction: ltr;
  position: relative;
  margin-bottom: 5rem;
  padding: 2rem 0;
}
.works-img-row::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
  scroll-behavior: smooth;
  /* direction: rtl; */
  height: 10px;
}

.works-img-row::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.works-img-row::-webkit-scrollbar-thumb {
  background-color: #575a5f; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.works-img-row .banner {
  position: relative;
  width: 100%;
  height: 100%;
}
.works-img-row .banner .icon {
  position: absolute;
  content: "";
  top: 45%;
  left: 92%;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #66fcf1;
  font-size: 3rem;
  font-weight: 600;
  transition: all 0.3s linear;
  animation: animate 1s linear infinite;
  animation-iteration-count: 10;
  display: none;
}
.paused {
  animation-play-state: paused !important;
}
.works-img-row .banner .icon .arrow {
  cursor: pointer;
}
@keyframes animate {
  0% {
    left: 92%;
  }

  50% {
    left: 94%;
  }

  100% {
    left: 92%;
  }
}

.works-img-row .other-imgs {
  position: relative;
  display: flex;
  width: 0%;
  /* justify-content: center; */
  align-items: center;
  /* overflow-x: scroll; */
}
.works-img-row .other-imgs .other-img {
  min-width: 20rem;
  border: 1px solid #000;
  height: 100%;
}
.works-img-row .banner img,
.works-img-row .other-imgs img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.works-img-row .other-imgs img {
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .works-img-row {
    width: 90%;
    height: 35rem;
  }
  .works-img-row .banner img,
  .works-img-row .other-imgs img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .works-img-row .banner .icon {
    left: 75%;
  }

  .works-img-row::-webkit-scrollbar {
    width: 24px; /* width of the entire scrollbar */
    scroll-behavior: smooth;
    /* direction: rtl; */
    height: 10px;
  }
}
