.about-container {
  background: #0b0c10;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}
.about-container .particles-container {
  height: 50vh !important;
}
.about-container .header {
  z-index: 100;
  background: transparent;
}
.header-container {
  position: relative;
  width: 100%;
  height: 6rem !important;
}
.header-details {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.about-container .about-details {
  position: relative;
  width: 100%;
  height: 100%;
}
.about-container .about-info {
  position: relative;
  width: 100%;
  height: 45vh;
}
.about-container .intro {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.about-container .intro .title {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.about-container .services-section .cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}
.about-container .services-section .services-section-card {
  background: transparent;
  padding: 2.5rem;
  width: 30%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 0.1rem;
  word-wrap: break-word;
  margin-top: 1rem;
  margin-right: 1rem;
  border: 3px solid transparent;
}
.about-container .services-section .services-section-card {
  border: none;
  border-bottom: 3px solid transparent;
  height: auto;
}
.about-container .services-section .services-section-card:hover {
  border-bottom: 3px solid transparent;
}

.about-container .services-section .services-section-card .title {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2em;
  color: #fff;
  margin: 1rem 0;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.about-container .services-section .services-section-card .icon {
  color: #66fcf1;
  font-size: 3rem;
  margin: 1.5rem 0;
}
.about-container .services-section .services-section-card .desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  color: #fff;
  letter-spacing: 0.3px;
  margin-bottom: 1rem;
}

.about-container .services-section .services-section-card .icon {
  margin: 1rem 0;
}
.about-container .services-section .services-section-card .desc {
  margin-bottom: 0.5rem;
}
.about-container .services-section .services-section-card .read-more-btn {
  margin-top: 1.5rem;
  display: none;
}
.about-container .services-section .services-section-card .read-more-btn .btn {
  background: #45a29e00;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  border: 2px solid #66fcf1;
  padding: 10px 15px 10px 15px;
  color: #fff;
  letter-spacing: 0.3px;
  transition: all 0.2s linear;
}
.about-container
  .services-section
  .services-section-card
  .read-more-btn
  .btn:hover {
  border: 2px solid #39928b;
}

.about-container .services-section .vertical-bar {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

@keyframes servicecardanimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.1;
  }
}

.about-container .skills-section {
  background: #121212;
  padding: 4rem 0;
}
.about-container .skills-section .skills-part .details {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 4rem 2rem;
  flex-direction: row;
}
.about-container .skills-section .skills-part-two .details {
  flex-direction: row-reverse;
}
.about-container .skills-section .skills-part .details .thumbnail {
  width: 40%;
  height: 70vh;
  background: url("./images/bg-img-3.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}
.about-container .skills-section .skills-part-two .details .thumbnail {
  background: url("./images/bg-img-2.jpg");
  background-size: cover;
  background-position: center center;
}
.about-container .skills-section .skills-part .details .info {
  width: 50%;
  padding: 0 1rem;
}
.about-container .skills-section .skills-part-two .details .info {
  margin-top: 4rem;
}
.about-container .skills-section .skills-part .details .info .title {
  color: #fff;
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.about-container .skills-section .skills-part .details .info .text {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  text-align: justify;
  margin-bottom: 1rem;
}

.about-container .skills-section .skills-part-one .info .list {
  /* padding: 0 2rem; */
  width: 100%;
  position: relative;
}
.about-container .skills-section .skills-part-one .info .list ul {
  width: 100%;
  margin-top: 2rem;
}
.about-container .skills-section .skills-part-one .info .list ul .skill {
  width: 100%;
  border-bottom: 2px solid #66fcf1;
  margin-bottom: 2rem;
  color: #fff;
  line-height: 2em;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
  .p {
    height: 75vh !important;
  }
  .about-container .particles-container {
    height: 55vh !important;
  }
  .about-container {
    max-width: 100%;
    overflow-x: hidden;
  }
  .about-container .intro {
    height: 70%;
  }
  .about-container .intro .title {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2em;
    letter-spacing: 0.5px;
  }
  .about-container .info {
    margin-top: 0rem;
  }
  .about-container .about-section .details {
    padding: 0rem;
  }
  .about-container .services-section .services-section-card {
    width: 100%;
    justify-content: center;
    margin: 0rem auto;
    margin-top: 1rem;
  }

  .about-container .skills-section {
    padding: 2remrem 0;
  }
  .about-container .skills-section .skills-part .details {
    padding: 2rem 0;
    flex-direction: column-reverse;
  }

  .about-container .skills-section .skills-part .details .thumbnail {
    width: 90%;
    height: 60vh;
  }
  .about-container .skills-section .skills-part .details .info {
    width: 90%;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  .about-container .skills-section .skills-part .details .info .title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .about-container .skills-section .skills-part .details .list {
    width: 100%;
  }
}
