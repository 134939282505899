.contactus-container {
  background: #0b0c10;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}
.contactus-container .particles-container {
  height: 55vh !important;
}
.contactus-container .header {
  z-index: 100;
  background: transparent;
}
.header-container {
  position: relative;
  width: 100%;
  height: 6rem !important;
}
.header-details {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.contactus-container .contactus-details {
  position: relative;
  width: 100%;
  height: 100%;
}
.contactus-container .contactus-info {
  position: relative;
  width: 100%;
  height: 45vh;
}
.contactus-container .intro {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex-direction: column;
}

.contactus-container .intro .title,
.contactus-container .intro .sub-title {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  padding-bottom: 2rem;
}
.contactus-container .intro .sub-title {
  font-size: 3rem;
  padding-bottom: 4rem;
}
.contactus-container .info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}
.contactus-container .info .title {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.2em;
  letter-spacing: 0.5px;
}
.contactus-container .info .text {
  font-size: 1rem;
  font-weight: 400;
  color: #66fcf1;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin: 1.5rem 0;
}

.contactus-container .services-section .vertical-bar {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.contactus-container .contactusdetails-section {
  margin-top: 0rem;
  position: relative;
  width: 100%;
  background: #0b0c10;
  height: 100%;
  padding-bottom: 2rem;
  /* margin-bottom: 6rem; */
}
.contactus-container .contactusdetails-section .info {
  padding: 0 0rem;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  position: relative;
  flex-direction: row;
}
.contactus-container .contactusdetails-section .info .box {
  min-height: 40%;
  border-right: 3px solid #66fcf1;
  border-left: 3px solid #66fcf1;
  padding: 0.5rem 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33%;
}
.contactus-container .contactusdetails-section .info .box .icon {
  color: #66fcf1;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 1.5rem;
}

.contactus-container .contactusdetails-section .info .box .title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 1rem;
}
.contactus-container .contactusdetails-section .info .box .text {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.8em;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  width: 70%;
}
.contactus-container .contactusdetails-section .info .box .text span {
  margin-bottom: 0.5rem;
  text-transform: initial;
  letter-spacing: 0.5px;
}
.contactus-container .contactusdetails-section .info .box:nth-child(3),
.contactus-container .contactusdetails-section .info .box:nth-child(1) {
  border: none;
}

.contactus-container .query {
  padding: 2rem 0;
  background: #121212;
}

@media screen and (max-width: 768px) {
  .contactus-container {
    max-width: 100%;
    overflow-x: hidden;
  }
  .contactus-container .particles-container {
    height: 50vh !important;
  }
  .contactus-container .intro {
    height: 75%;
  }
  .contactus-container .intro .title,
  .contactus-container .intro .sub-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2em;
    letter-spacing: 0.5px;
  }
  .contactus-container .intro .sub-title {
    font-size: 1.25rem;
  }
  .contactus-container .info {
    background: #0b0c10;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0rem;
  }
  .contactus-container .info .title {
    font-size: 2.5rem;
    font-weight: 500;
    color: #fff;
    line-height: 1.2em;
    letter-spacing: 0.5px;
    text-align: center;
  }
  .contactus-container .info .text {
    font-size: 1rem;
    font-weight: 400;
    color: #66fcf1;
    line-height: 1.8em;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin: 1.5rem 0;
  }
  .contactus-container .contactusdetails-section .info {
    flex-direction: column;
  }
  .contactus-container .contactusdetails-section .info .box {
    width: 100%;
    margin-bottom: 1rem;
    border: none;
    text-align: center;
  }

  .contactus-container .contactusdetails-section .info .box .text {
    text-align: left;
    width: 90%;
  }
}
