/* contact section */
.contact-section {
  position: relative;
  background: #0b0c10;
  width: 100%;
  height: 100%;
  margin-top: 0rem;
}

.contact-section .company-details {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 2rem 1rem;
}
.contact-section .company-details .logo {
  /* width: 10rem;
  height: 5rem; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.contact-section .company-details .logo p {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.contact-section .company-details .logo .horizontal-bar {
  position: relative;
  width: 10rem;
  height: 2rem;
  border-bottom: 3px solid #66fcf1;
}
.contact-section .company-details .logo img {
  width: 100%;
  height: 100%;
}

.contact-section .company-details .social-links {
  /* background: lightcoral; */
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 2rem;
}
.contact-section .company-details .social-links .link {
  padding-right: 2rem;
  font-size: 1.75rem;
  color: #fff;
}
.contact-section .company-details .social-links .link:hover {
  color: #66fcf1;
}

.contact-section .company-details .contact-info {
  text-align: left;
  width: 20%;
  /* background: #fff; */
}
.contact-section .company-details .contact-info .contact-title {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2em;
  letter-spacing: 0.3px;
  margin-bottom: 1rem;
}

.contact-section .company-details .contact-info ul .contact-item {
  margin-bottom: 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.3px;
  display: flex;
  /* align-items: center; */
  height: 100%;
}
.contact-section .company-details .contact-info ul .contact-item .icon {
  font-size: 1.3rem;
  /* margin-top: 0.35rem; */
  padding-right: 1rem;
}
.contact-section .company-details .contact-info ul .contact-item .text {
  /* width: 30%; */
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5em;
}

@media screen and (max-width: 768px) {
  .contact-section .company-details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-section .company-details .logo {
    margin-bottom: 2rem;
    width: 10rem;
    height: 5rem;
  }
  .contact-section .company-details .logo .horizontal-bar {
    width: 10rem;
  }
  .contact-section .company-details .social-links {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 3rem;
  }
  .contact-section .company-details .social-links .link {
    padding-right: 0rem;
  }
  .contact-section .company-details .contact-info {
    width: 100%;
    margin-left: 1.5rem;
    margin-top: 2rem;
  }
  .contact-section .company-details .contact-info .title {
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contact-section .company-details .social-links {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 3rem;
  }
}
